import { Route, Routes, useLocation } from "react-router-dom";
import "./App.css";
import Header from "./component/Header/Header";
import { AnimatePresence } from "framer-motion";
import About from "./page/About/About";
import Home from "./page/Home/Home";
import React, { useContext, useEffect, useState } from "react";

import Projects from "./page/Projects/Projects";
import Contact from "./page/Contact/Contact";
import axios from "axios";
import { API } from "./config/Api";

function App() {
  const [dataCat, setDataCat] = useState();

  const location = useLocation();
  useEffect(() => {
    axios
      .get(`${API.About.client_about_get}`, {
        headers: {
          "Accept-Language": "en",
          Accept: "application/json",
        },
      })
      .then((res) => {
        console.log(res.data);
        setDataCat(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  return (
    <>
      <Header />
      <AnimatePresence mode="wait">
        <Routes location={location} key={location.pathname}>
          <Route path="/" index element={<Home />} />
          <Route path="/About" element={<About dataCat={dataCat} />} />
          <Route path="/Projects" element={<Projects />} />
          <Route path="/ContactUs" element={<Contact dataCat={dataCat} />} />
        </Routes>
      </AnimatePresence>
    </>
  );
}

export default App;
