import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import "./global/Main.css";
import { PrimeReactProvider } from "primereact/api";

import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";

import { BrowserRouter } from "react-router-dom";
import { AuthProvider } from "./context/Context";
import "./i18n";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <PrimeReactProvider>
    <BrowserRouter>
      <AuthProvider>
        <App />
      </AuthProvider>
    </BrowserRouter>
  </PrimeReactProvider>
);
