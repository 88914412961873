import React, { useContext, useEffect, useState } from "react";
import transation from "../../component/Transation/Transation";
import "./About.css";
import { API } from "../../config/Api";
import axios from "axios";
import { Paginator } from "primereact/paginator";
import { AuthContext } from "../../context/Context";

const About = ({ dataCat }) => {
  const { t, i18n } = useContext(AuthContext);

  useEffect(() => {
    window.history.scrollRestoration = "manual";
    window.scroll(0, -80);
  }, [window]);

  const [data, setData] = useState([]);
  const [wait, setWait] = useState(false);
  const pageSize = 3;
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(pageSize);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState("");
  const onPageChange = (event) => {
    let currentPage = event.page + 1;
    setCurrentPage(currentPage);
    setFirst(event.first);
    setRows(event.rows);
    setWait(true);
    axios
      .get(
        `${
          API.Service.client_service_get
        }?page=${currentPage}&size=${pageSize}&isPaginate=${1}`,
        {
          headers: {
            Accept: "application/json",
          },
        }
      )
      .then((res) => {
        console.log(res.data.data);
        setData(res?.data?.data?.data);
        setTotalItems(res?.data?.data?.totalItems);
        setWait(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    setWait(true);
    axios
      .get(
        `${
          API.Service.client_service_get
        }?page=${currentPage}&size=${pageSize}&isPaginate=${1}`,
        {
          headers: {
            Accept: "application/json",
          },
        }
      )
      .then((res) => {
        console.log(res.data);
        setData(res?.data?.data?.data);
        setTotalItems(res?.data?.data?.totalItems);
        setWait(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [currentPage]);
  return (
    <section className="about">
      <div className="container">
        <div className="header_about">
          <h2
            style={{
              fontFamily: i18n.language === "ar" ? "Cairo" : "",
            }}
          >
            {t("menu.About")}
          </h2>
          <p
            style={{
              fontFamily: i18n.language === "ar" ? "Cairo" : "",
              letterSpacing: i18n.language === "ar" ? "0" : "",
              fontSize: i18n.language === "ar" ? "16px" : "",
              direction: i18n.language === "ar" ? "rtl" : "",
            }}
          >
            {i18n.language === "ar" ? dataCat.bio_ar : dataCat?.bio}
          </p>
        </div>
        <div className="body_about">
          <div
            className="header_ser"
            style={{
              right: i18n.language === "ar" ? "20px" : "",
            }}
          >
            <h2
              style={{
                fontFamily: i18n.language === "ar" ? "Cairo" : "",
                padding:
                  i18n.language === "ar" ? "0px 0 0px 10px" : "",
              }}
              att={i18n.language === "ar" ? "تي" : "My "}
            >
              {t("menu.OurServices")}
            </h2>
          </div>
          <div
            className="items"
            style={{
              direction: i18n.language === "ar" ? "rtl" : "",
            }}
          >
            {wait ? (
              <div
                style={{
                  height: "460px",
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <span className="loader"></span>
              </div>
            ) : (
              data.map((item, index) => {
                return (
                  <div key={index} className="item">
                    <div className="right">
                      <i className="bx bx-globsse">
                        <span>{item.id}</span>
                      </i>
                    </div>
                    <div className="left">
                      <h2
                        style={{
                          fontFamily: i18n.language === "ar" ? "Cairo" : "",
                          direction: i18n.language === "ar" ? "rtl" : "",
                          letterSpacing: i18n.language === "ar" ? "0" : "",
                          fontSize: i18n.language === "ar" ? "35px" : "",
                        }}
                      >
                        {i18n.language === "ar" ? item.title_ar : item.title}
                      </h2>
                      <p
                        style={{
                          fontFamily: i18n.language === "ar" ? "Cairo" : "",
                          direction: i18n.language === "ar" ? "rtl" : "",
                          fontSize: i18n.language === "ar" ? "16px" : "",
                        }}
                      >
                        {i18n.language === "ar"
                          ? item.description_ar
                          : item.description}
                      </p>
                    </div>
                  </div>
                );
              })
            )}
            {data.length === 0 && (
              <div
                style={{
                  height: "460px",
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontSize: "50px",
                  color: "var(--Pr-color)",
                }}
              >
                No Projects
              </div>
            )}
          </div>
          <div className="card">
            <Paginator
              first={first}
              rows={rows}
              totalRecords={totalItems}
              onPageChange={onPageChange}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default transation(About);
