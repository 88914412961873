import React, { useContext, useEffect, useState } from "react";
import "./Contact.css";
import transation from "../../component/Transation/Transation";
import axios from "axios";
import { API } from "../../config/Api";
import Toast from "../../component/Sweetalert/Sweetalert";
import { AuthContext } from "../../context/Context";

const Contact = ({ dataCat }) => {
  const { t, i18n } = useContext(AuthContext);
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [question, setQuestion] = useState("");
  useEffect(() => {
    window.history.scrollRestoration = "manual";
    window.scroll(0, -80);
  }, [window]);
  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("email", email);
    formData.append("full_name", name);
    formData.append("question", question);
    axios
      .post(API.Contact.client_contact_add, formData, {
        headers: {
          Accept: "application/json",
        },
      })
      .then((res) => {
        console.log(res.data);
        if (res.data.status) {
          Toast.fire({
            text: `${res.data.message}`,
            icon: "success",
          });
          setEmail("");
          setName("");
          setQuestion("");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <section className="contact">
      <div className="container">
        <div className="header_contact">
          <h2
            style={{
              fontFamily: i18n.language === "ar" ? "Cairo" : "",
              letterSpacing: i18n.language === "ar" ? "0" : "",
            }}
          >
            {t("menu.contact")}
          </h2>
        </div>
      </div>
      <div
        className="body_contact"
        style={{
          direction: i18n.language === "ar" ? "rtl" : "",
        }}
      >
        <div className="header_con">
          <h2
            className={`${i18n.language === "ar" ? "abs" : ""}`}
            style={{
              fontFamily: i18n.language === "ar" ? "Cairo" : "",
              letterSpacing: i18n.language === "ar" ? "0" : "",
            }}
            att={i18n.language === "ar" ? "اتصل" : "Con"}
          >
            {t("Contact_p")}
          </h2>
          <div className="social">
            <div className="address icon">
              <i className="bx bxs-map"></i>
              <p
                style={{
                  fontFamily: i18n.language === "ar" ? "Cairo" : "",
                  letterSpacing: i18n.language === "ar" ? "0" : "",
                }}
              >
                {i18n.language === "ar" ? dataCat.address_ar : dataCat?.address}
              </p>
            </div>
            <div className="phone icon">
              <i className="bx bxs-phone"></i>
              <p
                style={{
                  fontFamily: i18n.language === "ar" ? "Cairo" : "",
                  letterSpacing: i18n.language === "ar" ? "0" : "",
                  direction: i18n.language === "ar" ? "ltr" : "",
                }}
              >
                {dataCat?.phone_mobile}
              </p>
            </div>
            <div className="gmail icon">
              <i className="bx bxl-gmail"></i>
              <p>{dataCat?.email}</p>
            </div>
          </div>
        </div>
        <div className="left">
          <form onSubmit={handleSubmit}>
            <div className="form_child">
              <div
                className={`child ${email !== "" ? "dinone" : ""} ${
                  i18n.language === "ar" ? "abs" : ""
                }`}
                label={`${
                  email !== ""
                    ? i18n.language === "ar"
                      ? "الايميل"
                      : "Your email"
                    : ""
                }`}
              >
                <input
                  style={{
                    fontSize: i18n.language === "ar" ? "14px" : "",
                    fontFamily: i18n.language === "ar" ? "Cairo" : "",
                    letterSpacing: i18n.language === "ar" ? "0" : "",
                  }}
                  placeholder={
                    i18n.language === "ar" ? "الايميل" : "Your email"
                  }
                  type="email"
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                  required
                />
              </div>
              <div
                className={`child ${name !== "" ? "dinone" : ""} ${
                  i18n.language === "ar" ? "abs" : ""
                }`}
                label={`${
                  name !== ""
                    ? i18n.language === "ar"
                      ? "الاسم الكامل"
                      : "Full Name"
                    : ""
                }`}
              >
                <input
                  style={{
                    fontSize: i18n.language === "ar" ? "14px" : "",
                    fontFamily: i18n.language === "ar" ? "Cairo" : "",
                    letterSpacing: i18n.language === "ar" ? "0" : "",
                  }}
                  type="text"
                  placeholder={
                    i18n.language === "ar" ? "الاسم الكامل" : "Full Name"
                  }
                  value={name}
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                  required
                />
              </div>
            </div>
            <div className="form_child">
              <div
                className={`child ${question !== "" ? "dinone" : ""}  ${
                  i18n.language === "ar" ? "abs" : ""
                }`}
                label={`${
                  question !== ""
                    ? i18n.language === "ar"
                      ? "ماهي اسئلتك ..."
                      : "Question"
                    : ""
                }`}
              >
                <textarea
                  style={{
                    fontSize: i18n.language === "ar" ? "14px" : "",
                    fontFamily: i18n.language === "ar" ? "Cairo" : "",
                    letterSpacing: i18n.language === "ar" ? "0" : "",
                  }}
                  placeholder={
                    i18n.language === "ar" ? "ماهي اسئلتك ..." : "Question"
                  }
                  value={question}
                  onChange={(e) => {
                    setQuestion(e.target.value);
                  }}
                  rows={7}
                  required
                />
              </div>
            </div>
            <div className="btn">
              <button
                style={{
                  fontSize: i18n.language === "ar" ? "14px" : "",
                  fontFamily: i18n.language === "ar" ? "Cairo" : "",
                }}
              >
                {t("Send_Btn")}
              </button>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
};

export default transation(Contact);
