import React, { useContext } from "react";
import transation from "../../component/Transation/Transation";
import "./Home.css";
import { AuthContext } from "../../context/Context";

const Home = () => {
  const { t, i18n } = useContext(AuthContext);
  return (
    <>
      <section className="home">
        <img src={require("../../assets/IMG_8688-removebg-preview.png")} alt="image" />
        <h2
          style={{
            fontFamily: i18n.language === "ar" ? "Cairo" : "",
            letterSpacing: i18n.language === "ar" ? "0" : "",
            wordSpacing: i18n.language === "ar" ? "20px" : "",
            whiteSpace: i18n.language === "ar" ? "nowrap" : "",
            // transform: i18n.language === "ar" ? "translate(-50%, 30%)" : "",
          }}
        >
          {t("home_b_r.h2")}
        </h2>
        <p
          style={{
            fontFamily: i18n.language === "ar" ? "Cairo" : "",
            letterSpacing: i18n.language === "ar" ? "0" : "",
            wordSpacing: i18n.language === "ar" ? "10px" : "",
            fontSize: i18n.language === "ar" ? "18px" : "",
          }}
        >
          {t("home_b_r.h2_p")}
        </p>
      </section>
      <div className="about_me">
        <h1
          style={{
            fontFamily: i18n.language === "ar" ? "Cairo" : "",
            letterSpacing: i18n.language === "ar" ? "0" : "",
            wordSpacing: i18n.language === "ar" ? "10px" : "",
          }}
        >
          {t("home_b_r.h1")}
        </h1>
        <p
          style={{
            fontFamily: i18n.language === "ar" ? "Cairo" : "",
            letterSpacing: i18n.language === "ar" ? "0" : "",
            fontSize: i18n.language === "ar" ? "16px" : "",
            direction: i18n.language === "ar" ? "rtl" : "",
          }}
        >
          {t("home_b_r.p")}
        </p>
      </div>
    </>
  );
};

export default transation(Home);
