import React, { useContext, useEffect, useState } from "react";
import "./Header.css";
import { Link, useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/Context";

const Header = () => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const { t, i18n, showOpen, setShowOpen, activeMenu, setActiveMenu } =
    useContext(AuthContext);
  const nav = useNavigate();
  useEffect(() => {
    setActiveMenu("/");
    nav("/");
    const handleScroll = () => {
      setScrollPosition(window.scrollY);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <header className={`header ${scrollPosition > 5 ? "scrollClassName" : ""}`}>
      <nav
        className="nav container"
        style={{
          direction: i18n.language === "ar" ? "rtl" : "",
        }}
      >
        <Link
          to="/"
          onClick={() => {
            setActiveMenu("/");
            setShowOpen(false);
          }}
          className="nav_logo"
        >
          <img
            src={require("../../assets/logo-saeed-owis.png")}
            alt="logo-saeed-owis"
          />
        </Link>
        <div
          className={`nav_menu ${showOpen ? "show-menu" : ""}`}
          id="nav-menu"
        >
          <ul className="nav_list">
            {/* Home */}
            <li className="nav_item">
              <Link
                to="/"
                onClick={() => {
                  setActiveMenu("/");
                  setShowOpen(false);
                }}
                style={{ direction: i18n.language === "ar" ? "ltr" : "" }}
                className={`nav_link  ${activeMenu === "/" ? "active" : ""}`}
              >
                <i
                  className={`${
                    i18n.language === "ar"
                      ? "bx bxs-left-arrow-alt"
                      : "bx bxs-right-arrow-alt"
                  }`}
                ></i>
                <span className={`${i18n.language === "ar" ? "arLang" : ""}`}>
                  {t("menu.home")}
                </span>
              </Link>
            </li>
            {/* About */}
            <li className="nav_item">
              <Link
                to="/About"
                onClick={() => {
                  setActiveMenu("About");
                  setShowOpen(false);
                }}
                style={{ direction: i18n.language === "ar" ? "ltr" : "" }}
                className={`nav_link ${activeMenu === "About" ? "active" : ""}`}
              >
                <i
                  className={`${
                    i18n.language === "ar"
                      ? "bx bxs-left-arrow-alt"
                      : "bx bxs-right-arrow-alt"
                  }`}
                ></i>
                <span className={`${i18n.language === "ar" ? "arLang" : ""}`}>
                  {t("menu.About")}
                </span>
              </Link>
            </li>
            {/* MyBusiness */}
            <li className="nav_item">
              <Link
                to="/Projects"
                onClick={() => {
                  setActiveMenu("Projects");
                  setShowOpen(false);
                }}
                style={{ direction: i18n.language === "ar" ? "ltr" : "" }}
                className={`nav_link ${
                  activeMenu === "Projects" ? "active" : ""
                }`}
              >
                <i
                  className={`${
                    i18n.language === "ar"
                      ? "bx bxs-left-arrow-alt"
                      : "bx bxs-right-arrow-alt"
                  }`}
                ></i>
                <span className={`${i18n.language === "ar" ? "arLang" : ""}`}>
                  {t("menu.MyBusiness")}
                </span>
              </Link>
            </li>
            {/* Contact Us */}
            <li className="nav_item">
              <Link
                to="/ContactUs"
                onClick={() => {
                  setActiveMenu("ContactUs");
                  setShowOpen(false);
                }}
                style={{ direction: i18n.language === "ar" ? "ltr" : "" }}
                className={`nav_link ${
                  activeMenu === "ContactUs" ? "active" : ""
                }`}
              >
                <i
                  className={`${
                    i18n.language === "ar"
                      ? "bx bxs-left-arrow-alt"
                      : "bx bxs-right-arrow-alt"
                  }`}
                ></i>
                <span className={`${i18n.language === "ar" ? "arLang" : ""}`}>
                  {t("menu.contact")}
                </span>
              </Link>
            </li>
          </ul>
          {/* close Button */}
          <div
            className="nav_close"
            onClick={() => {
              setShowOpen(false);
            }}
            id="nav-close"
          >
            <i className="bx bxl-xing"></i>
          </div>
          {/* Transalete */}
          <div className="nav_tra">
            <select
              onChange={() => {
                if (i18n.language === "en") {
                  i18n.changeLanguage("ar");
                } else {
                  i18n.changeLanguage("en");
                }
              }}
            >
              <option value="en">en</option>
              <option value="ar">ar</option>
            </select>
          </div>
        </div>
        <div
          className="nav_toggle"
          onClick={() => {
            setShowOpen(true);
          }}
          id="nav-toggle"
        >
          <i className={`${ i18n.language === "ar"?"bx bx-menu-alt-left":"bx bx-menu-alt-right"} `}></i>
        </div>
      </nav>
    </header>
  );
};

export default Header;
